<template>
	<div class="login-page">
<!--		<form @submit.prevent="onSubmit" method="post" action="//backend.fifty-seven.com/login">-->
<!--			<div class="error">{{error}}</div>-->
<!--			<input name="login" type="text" v-model="login" placeholder="Login">-->
<!--			<input name="password" type="password" v-model="password" placeholder="Password">-->
<!--			<button type="submit">Sign in</button>-->
<!--		</form>-->

		<h1>Sign in</h1>
		<form @submit.prevent="onSubmit">
			<div ref="alert" class="alert alert-dismissible fade" :class="[{show: alertVisible}, `alert-${alertType || 'danger'}`]" role="alert">
				<strong>{{alertTitle}}!</strong> {{alertMessage}}
			</div>
			<div class="mb-3">
				<label for="login" class="form-label">Login</label>
				<input type="text" class="form-control" id="login" v-model="login" autocomplete="username">
			</div>
			<div class="mb-3">
				<label for="password" class="form-label">Password</label>
				<input type="password" class="form-control" id="password" v-model="password" autocomplete="current-password">
			</div>

			<button class="btn btn-primary" type="submit">Sign in</button>
		</form>
	</div>
</template>

<script>
	import {authorizationService} from "@/modules/authorization/authorization.service";
	import {api} from "@/modules/common/api";

	export default {
		name: "LoginPage",
		data: () => ({
			login: '',
			password: '',
			alertVisible: false,
			alertType: 'danger',
			alertTitle: 'Error',
			alertMessage: '',
			alertTimeout: null,
		}),
		methods: {
			showAlert(message, isSuccess = false) {
				if (isSuccess) {
					this.alertType = 'success';
					this.alertTitle = 'Success';
				} else {
					this.alertType = 'danger';
					this.alertTitle = 'Error';
				}

				this.alertMessage = message;
				this.alertVisible = true;

				clearTimeout(this.alertTimeout);
				this.alertTimeout = setTimeout(() => this.alertVisible = false, 1000 * 10);
			},

			async onSubmit() {
				const login = this.login;
				const password = this.password;

				this.alertVisible = false;

				try {
					const accessToken = await authorizationService.signIn(login, password);
					if (accessToken) {
						localStorage.setItem('accessToken', accessToken);
						api.reconnect();

						this.login = '';
						this.password = '';
					}
				} catch (err) {
					this.showAlert(err.toString());
				}
			}
		}
	}
</script>

<style scoped>
	.login-page {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	form {
		width: 20em;
	}

	button {
		width: 100%;
	}

	/*form {*/
	/*	display: flex;*/
	/*	flex-direction: column;*/
	/*}*/

	/*input,*/
	/*button {*/
	/*	padding: 0.5em;*/
	/*	margin-bottom: 0.6em;*/
	/*}*/
</style>
